@media screen and (max-width: 1240px) {
    body .site {
        width: auto;
    }
    body .site > header,
    body .site main #view-section,
    body .site main .main-view {
        width: 1030px;
        padding: 0 calc((100% - 1030px) / 2);
        margin: 0 auto;
    }
    body .site > header {
        -webkit-box-sizing: content-box;
                box-sizing: content-box;
    }
    body .social-block,
    body .social-block.main {
        right: calc((100% - 1030px) / 2);
    }
    
    
    

    body #news-section .content-list-container,
    body #events-section .content-list-container{
        margin-left: 0;
        margin-right: 0;
    }
    body #news-section h1,
    body #events-section h1{
        margin-left: 0;
    }
    
    
    body #view-news-section .main-view .content-list-container {
        margin: 50px 0 0 0;
        font-size: 0;
    }
    body #view-news-section .main-view .content-list-container .heading h2 {
        font-size: 28px;
    }
    body #view-news-section .main-view .content-list-container h2 {
        font-size: 18px;
    }
    body #view-news-section .content-list-elements {
        width: calc(33.3% - 26px);
    }

    body #view-news-section .navigation-numbers {
		left: calc(50% - 30px);
	}
	body #view-news-section ul.pagination li:first-of-type {
		top: 40px;
		left: 30px;
	}
	body #view-news-section ul.pagination li:last-child {
		top: 40px;
		right: 30px;
	}
    
    body #contact-section #mapDiv {
        margin: 60px calc((100% - 1030px) / -2) 0;
    }
    
    .site main {
        width: 100%;
        margin: 0 auto;
    }
    
    
    body .galeriak div#pager {
        width: 100%;
        left: 0;
    }
    body .gallery .navigation-numbers {
		left: calc(50% - 30px);
		top: 120px;
	}
	body #gallery-section .gallery h1 {
	    margin-bottom: 80px;
	}
	body .gallery .navigation-numbers {
        top: 115px;
        left: calc(50% - 18px);
        background: black;
        color: yellow;
        font-weight: 900;
        font-size: large;
	}
	body .gallery ul.pagination li:first-of-type {
		top: 110px;
		background: yellow;
		left: 0;
	}
	body .gallery ul.pagination li:last-child {
		top: 110px;
		background: yellow;
		right: 0;
	}
}

@media screen and (max-width: 1040px) {
    body .site > header,
    body .site {
        width: 100%;
    }
    body .site main #view-section,
    body .site main .main-view {
        width: 100%;
        padding: 0 16px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    body .social-block,
    body .social-block.main {
        right: 16px;
    }

    
    
    body .navbar .container {
        -ms-flex-flow: row nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
                flex-flow: row nowrap;
    }
    body .navbar-header.page-scroll {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        width: 100%;
        height: 140px;
    }
    body .navbar-default .navbar-brand {
        /*background-size: 100%;*/
        width: 100%;
        height: 100%;
    }
    body .navbar-collapse {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
    
    
    body .top-nav-collapse .navbar-brand {
        display: none;
    }
    body .top-nav-collapse .navbar-collapse {
        width: 100%;
    }
    body .top-nav-collapse .navbar-collapse ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background-color: #f9e805;
        padding: 0 40px 0 20px;
    }
    body .top-nav-collapse .navbar-collapse ul.submenu {
        display: none;
    }
    body .top-nav-collapse ul li {
        -webkit-box-flex: 0;
        -ms-flex: 2 1 auto;
        flex: 2 1 auto;
        text-align: center;
    }
    body .top-nav-collapse ul.nav.navbar-nav > li:last-child > a{
        padding: 12px 8.5px;
    }
    
    
    body .site main .main-view .content-view-content {
        float: left;
    }
    body .site main .main-view .img-container {
        margin-right: -16px;
        float: right;
    }
    body #news-section .btn,
    body #events-section .btn{
        left: 16px;
    }
    
    body .site main .main-view.gallery {
        min-height: inherit;
    }
    
    
    
    
    body .gallery .content-list-elements {
        width: 33.3%;
    }
    body #contact-section .contacts div.icon.smaller {
        width: 50%;
    }    
    body #contact-section .contacts div.icon.longer {
        width: 50%;
    }    
    
    
    
    body #contact-section #mapDiv {
        margin: 60px -16px 0;
    }
    
    
    

    
    body #view-section .view-image-container {
        width: 45%;
        left: -50%;
        margin: 0;
        position: absolute;
        }
    body #view-section .article-content table {
        max-width: calc(100% - 32px);
    }
    
    body #view-section .article-content, #view-section .default-contentview {
        margin: 0 0 40px 33%;
    }
    /*body #view-section .rolunk .article-content {
        margin: 50px 0 40px 0%;
    }*/
    body #view-section header .article-date,
    body #view-section header .author {
        width: 30%;
    } 
    body #view-section header {
        padding-left: 0;
    }
    body #view-section .tamogatok .article-content {
        margin: 50px 0 40px 0%;
    }
    
    .main-view.konferenciak .content-view-content{
        margin-right: 0 !important;
    }
}


@media screen and (max-width: 950px) {
    body .navbar-default .navbar-brand {
        display: none;
    }
    body .navbar-header.page-scroll {
        height: auto;
    }
    body .navbar-collapse {
        width: 100%;
    }
    body .navbar-collapse ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background-color: #f9e805;
        padding: 0 40px 0 20px;
    }
    body ul.nav  li {
        -webkit-box-flex: 0;
        -ms-flex: 2 1 auto;
        flex: 2 1 auto;
        text-align: center;
    }
    body ul.nav > li > a {
        padding: 12px 7px;
    }
    
    /*submenu*/
    body .navbar-collapse ul .submenu {
        background-color: transparent;
        display: none;
        padding: 0 !important;
    }
    /*submenu vége*/
    
    body .LanguageSelectPortlet {
        height: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    body .language-select {
        display: block;
        margin: 0;
        position: relative;
        top: 3px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    body .language-select .inactive {
        display: block;
        position: static;
        right: 0;
        margin-bottom: 3px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    body .language-select ul {
        position: absolute;
        right: 0;
        width: initial;
        z-index: 5;
    }
    
    
    body .social-block.main {
        top: 100px;
        z-index: 1;
    }
    body #view-section .social-block {
        top: -15px;
        z-index: 1;
    }
    body .site main section#view-section {
        padding-top: 60px;
    }
    body .site main section#view-section .content-view-content {
        margin-top: 30px;
    }

    body #contact-section .contacts .icon {
        width: 50%;
    }
    /*body .social-block.main {
		top: 100px !important;
		right: 10px;
	}
	#intro-section .img-container-bg-img {
		background-position-x: -185px;
	}*/
	
	body .language-select {
        top: 10px;
    }
}

@media screen and (max-width: 900px) {
    body nav.top-nav-collapse {
        height: 50px;
        padding: 0;
        margin: 0;
    }
    
    body .top-nav-collepse .navbar-collapse ul.nav,
    body .navbar-collapse ul.nav {
        padding: 0 30px 0 0;
    }
    body ul.nav.top-nav-collepse > li > a,
    body ul.nav > li > a {
        font-size: 14px; 
    }
    

    body .site main .focim article {
        width: 100%;
        left: 0;
        padding: 0 0 0 35%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    body .site main .focim .article-lead {
        font-size: 21px;
    }
    body .site main .focim .article-body {
        font-size: 40px;
    }
    body .site main .article-lead {
        font-size: 18px;
    }
    body .site main .article-body {
        font-size: 15px;
    }
    body section h1 {
        font-size: 24px !important;
    }
    
    body #news-section .content-list-elements,
    body #events-section .content-list-elements{
        width: 45.4%;
    }
    
}

@media screen and (max-width: 875px) {
    body header nav {
        position: relative;
        background-color: #f9e805;
        height: 50px;
    }
    body .navbar-collapse {
        width: 100%;
        position: relative;
    }
    body .navbar-collapse ul.nav {
        display: block;
        height: 50px;
        width: 100%;
    }
    .after {
        display: block;
        position: absolute;
        right: 30px;
        height: 50px;
        width: 50px;
        background: url(../images/burger.svg) no-repeat center;
        background-size: 55%;
        z-index: 5;
    }  
    .after.active {
        background: url(../images/iksz-y.svg) no-repeat center;
        background-color: #000;
    }

    
    body ul.nav li {
        display: none;
        width: 100%;
        min-height: 42px;
        text-align: left;
    }
    .after.active + .collapse ul.nav li {
        display: inline-block;
    }
    
    .after.active + .collapse ul.nav.navbar-nav li {
        display: block;
        width: 100%;
        border-bottom: 1px solid #FFF;
    }
    
    .after.active + .collapse ul.nav.navbar-nav li:last-child {
        border-bottom: 1px solid transparent;
    }
    
    .after.active + .collapse ul.nav.navbar-nav li a{
        padding: 14px !important;
    }
    
    .after.active + .collapse ul.nav > li:first-child {
        /*display: none;*/
        height: 50px;
    }
    .after.active + .collapse ul.nav > li:first-child > a {
        padding: 0;
        font-size: 0;
        z-index: 0;
        background-color: #f9e805;
    }
    body ul.nav > li > a {
        padding: 11px 5% !important;
        background-color: #000;
        color: #ccc;
    }
    body ul.nav li.hidden > a {
        padding: 0;
    }    
    body ul.nav.navbar-nav > li > a:hover,
    body ul.nav.navbar-nav > li > a:focus {
        background-color: #f9e805;
        color: #000;
    }
    /*body ul.nav .hidden .page-scroll{
    	padding: 0px 5% !important;
    }*/
    
    /*submenu*/
    body #header-section .navbar-collapse ul.nav .is-parent .submenu,
    body #header-section .navbar-collapse ul.nav .with-submenu .submenu {
        background-color: #f9e805;
        padding: 0 !important;
        position: unset;
        height: unset !important;
        width: 100%;
    }
    body #header-section .navbar-collapse ul.nav .is-parent .submenu li a,
    body #header-section .navbar-collapse ul.nav .with-submenu .submenu li a{
        font-size: 14px;
        padding: 14px 20px;
    }
    body #header-section .navbar-collapse ul.nav .is-parent .submenu .submenu li a,
    body #header-section .navbar-collapse ul.nav .with-submenu .submenu .submenu li a{
        padding-left: 40px;
    }
    body #header-section .navbar-collapse ul.nav .is-parent .submenu li a:hover,
    body #header-section .navbar-collapse ul.nav .is-parent .submenu li a:focus,
    body #header-section .navbar-collapse ul.nav .with-submenu .submenu li a:hover,
    body #header-section .navbar-collapse ul.nav .with-submenu .submenu li a:focus,
    body #header-section .navbar-collapse ul.nav .is-parent a[aria-expanded="true"],
    body #header-section .navbar-collapse ul.nav .with-submenu a[aria-expanded="true"]{
        background-color: #f9e805;
        color: #000000;
    }
    
    body #header-section .navbar-collapse ul.nav .is-parent .submenu .submenu li a,
    body #header-section .navbar-collapse ul.nav .with-submenu .submenu .submenu li a{
        padding-left: 40px !important;
    }
    
    body #header-section .navbar-collapse ul.nav .with-submenu > a::after,
    body #header-section .navbar-collapse ul.nav .is-parent > a::after {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" fill="white"><path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/></svg>');
    }
    
    body #header-section .navbar-collapse ul.nav .submenu .with-submenu > a::after,
    body #header-section .navbar-collapse ul.nav .submenu .is-parent > a::after {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" fill="black"><path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/></svg>');
    }
    
    body #header-section .navbar-collapse ul.nav .submenu .with-submenu > a[aria-expanded="true"]::after,
    body #header-section .navbar-collapse ul.nav .submenu .is-parent > a[aria-expanded="true"]::after,
    body #header-section .navbar-collapse ul.nav .submenu .with-submenu > a:hover::after,
    body #header-section .navbar-collapse ul.nav .submenu .is-parent > a:hover::after {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" fill="white"><path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/></svg>');
    }
    /*submenu vége*/
    
    body .navbar-default .navbar-brand {
        display: block;
        position: absolute;
        background-image: url(../images/minifigura.jpg);
        background-size: contain;
        width: 200px;
        height: 50px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 10;
        top: 0;
    }

    
    
    /*
    body .social-block {
        top: 110px;
    }*/
    body .gallery .content-list-elements {
        width: 50%;
    }
    body .site main section#view-section {
        padding-top: 70px;
    }
    body .site main .article-lead {
        margin-top: 50px;
    }
    
    
    body #contact-section .contacts .icon.longer.envelope,
    body #contact-section .contacts .icon.longer.fund,
    body #contact-section .contacts .icon.longer.mail {
        width: 45%;
    }
    body #contact-section .contacts .icon.smaller.fb,
    body #contact-section .contacts .icon.smaller.phone {
        width: 55%;
    }
    
    body #view-news-section .content-list-elements {
        width: 100%;
        margin: 60px 0 20px;
    }
    body #view-news-section ul.pagination li:first-of-type {
        left: 16px;
    }
    body #view-news-section ul.pagination li:last-child {
        right: 16px;
    }
    
    /* NEW */
    ul.nav.navbar-nav,
    ul.nav.navbar-nav li,
    ul.nav.navbar-nav li a {
        box-sizing: content-box;
    }
    
    ul.nav.navbar-nav li:not(.hidden) {
        display: none;
    }
    
    ul.nav.navbar-nav li.highlight {
        max-width: 100%;
    }
    
    nav.navbar ul.nav.navbar-nav > li > a{
        display: flex;
        height: auto;
    }
    
    body .language-select,
    body .top-nav-collapse.language-select{
        top: 7px;
    }

    /* NEW END */

}
    
@media screen and (max-width: 655px) {
    body .site main .focim article {
        padding: 16px;
    }
    body .site main .main-view .img-container {
        display: none;
    }    
    
    body #news-section .content-list-elements,
    body #events-section .content-list-elements{
        width: 100%;
        margin: 20px 0 80px;
    }
    body #view-news-section .banner-image-container,
    body #news-section .banner-image-container,
    body #events-section .banner-image-container{
        max-height: 400px;
    }
    body #news-section .btn,
    body #events-section .btn{
        margin-bottom: 20px;
    }
    
    body .gallery .content-list-elements {
        width: 100%;
    }

    body #contact-section .contacts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    body #contact-section .contacts .icon {
        width: 100% !important;
       -webkit-box-flex: 0;
       -ms-flex: 0 1 auto;
       flex: 0 1 auto;
    }
    body #contact-section .contacts .icon.envelope,
    body #contact-section .contacts .icon.fund,
    body #contact-section .contacts .icon.mail,
    body #contact-section .contacts .icon.phone,
    body #contact-section .contacts .icon.fb {
        margin-left: 20%;
    }
    body #contact-section .contacts .icon.fb {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;        
    }
    
    
    body .kiemelt-container .content-list-elements {
        width: 50%;
    }
}

@media screen and (max-width: 500px) {
    body .site main .focim .article-lead p {
        font-size: 16px;
        line-height: 1.2em;
    }
    body .site main .focim .article-body p {
        font-size: 28px;
    }
    body .focim .img-container-bg-img {
        min-width: auto;
    }
    
    
    
    body #view-section .view-image-container {
        width: 100%;
        position: relative;
        bottom: 0;
        left: 0;
        float: none;
        display: inline-block;
    }
    body #view-section .view-image-container img {
        display: block;
        position: relative;
        width: 100%;
        /* max-height: 200px; */
        /* bottom: 60px; */
        left: 0;
        padding: 0;
        margin: 0;
    }
    body .site main .article-lead {
        margin-top: 20px;
    }
    body #view-section .article-content, #view-section .default-contentview {
        display: inline-block;
        margin: 0 0 40px 0;
        width: 100%;
    }
    body #view-section header {
        display: inline-block;
        height: auto;
        position: relative;
    }
/*    body #view-section header .article-date,
    body #view-section header .author {
        display: inline-block;
        width: auto;
        padding-right: 20px;
    }*/
    #view-section .kiemelt h1.content-list-title {
        width: 75%;
    }
    body .portlet.AttachmentPortlet {
        margin-top: 100px;
    }
    body .kiemelt-container .content-list-elements {
        width: 100%;
    }
    body #contact-section .contacts .icon.envelope,
    body #contact-section .contacts .icon.fund,
    body #contact-section .contacts .icon.mail,
    body #contact-section .contacts .icon.phone,
    body #contact-section .contacts .icon.fb {
        margin-left: 0;
        margin-right: 0;
    }
}
